import React, { useCallback, useMemo, useContext, useState } from "react";
import { Box, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import {
  DashboardTrucksData,
  TruckSummary,
  columnTypeTranslation,
  Can,
  Module,
} from "interfaces";
import { useNavigate } from "react-router-dom";
import { TruckGroup, TruckCard } from ".";
import {
  AddTrucksCerroAltoDialog,
  CustomSnackbar,
  Header,
  TruckIcon,
  ExcelIcon,
  TruckGroupSkeleton,
  OperatorAssignmentKpis,
  ScreenContainer,
  ContentPasteRounded,
} from "components";
import { TruckColumn } from "./TruckColumn";
import {
  UserContext,
  InfoSourceContext,
  TrucksDashboardContext,
} from "contexts";
import {
  AppRoute,
  filterDashboardTrucks,
  generateFilteredTrucksData,
  generateTrucksExcel,
} from "utils";
import { useSearch } from "hooks";
import Fuse from "fuse.js";
import { CloseSharp } from "@material-ui/icons";
import { ShiftOperativeTrucksDialog } from "./ShiftOperativeTrucksDialog";
import { PlannedMaintenancesDialog } from "./PlannedMaintenancesDialog";
import {
  OperatorAssignmentSpeedDial,
  DialAction,
} from "./OperatorAssigmentSpeedDial";

const filterOptions: Fuse.IFuseOptions<TruckSummary> = {
  keys: ["id"],
  threshold: 0.1,
  minMatchCharLength: 3,
};

const BYPASS_OUTDATED_DATA =
  process.env.REACT_APP_BYPASS_OUTDATED_DATA === "true";

export const TrucksDashboardScreen: React.FC = () => {
  const classes = useStyles();
  const withSpace = useMediaQuery((theme: Theme) => theme.breakpoints.up(1500));
  const navigate = useNavigate();
  const { isAllowedTo } = useContext(UserContext);
  const { magnetElapsedMinutes, firstLoadingSourceInfo, refetchingSourceInfo } =
    useContext(InfoSourceContext);
  const {
    data: trucks,
    firstLoading: loadingTrucks,
    refetching: refetchingTrucks,
    polling: pollingTrucks,
    refetch: refetchTrucksDashboard,
  } = useContext(TrucksDashboardContext);

  const [openCerroAltoDialog, setOpenCerroAltoDialog] =
    useState<boolean>(false);
  const [openOperativeTrucksDialog, setOpenOperativeTrucksDialog] =
    useState<boolean>(false);
  const [openPlannedMaintenancesDialog, setOpenPlannedMaintenancesDialog] =
    useState<boolean>(false);

  const [openSpeedDial, setOpenSpeedDial] = useState<boolean>(false);

  const isDataOutdated = useMemo(
    () =>
      !BYPASS_OUTDATED_DATA &&
      !firstLoadingSourceInfo &&
      !refetchingSourceInfo &&
      (magnetElapsedMinutes === null || magnetElapsedMinutes >= 15),
    [firstLoadingSourceInfo, magnetElapsedMinutes, refetchingSourceInfo]
  );

  const [searchValue, setSearchValue, , searchResults] =
    useSearch<TruckSummary>(trucks, filterOptions);

  const dashboardTrucksData: DashboardTrucksData = useMemo(
    () =>
      generateFilteredTrucksData(
        filterDashboardTrucks(!!searchValue, searchResults)
      ),
    [searchResults, searchValue]
  );

  const onOpenSpeedDial = useCallback((e: unknown, reason: string) => {
    if (reason === "mouseEnter" || reason === "toggle") setOpenSpeedDial(true);
  }, []);

  const onCloseSpeedDial = useCallback(() => setOpenSpeedDial(false), []);

  const onClickNavigate = useCallback(
    (truck: TruckSummary) => {
      navigate(AppRoute.TRUCK_DETAILS.replace(":equipId", truck.id));
    },
    [navigate]
  );

  const handleOnDialogClose = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
      if (reason !== "backdropClick") {
        setOpenCerroAltoDialog(false);
        setOpenOperativeTrucksDialog(false);
        setOpenPlannedMaintenancesDialog(false);
      }
    },
    []
  );

  const handleOnOperativeTrucksDialogClose = useCallback(
    () => setOpenOperativeTrucksDialog(false),
    []
  );

  const handleOnDialogComplete = useCallback(
    () => refetchTrucksDashboard(),
    [refetchTrucksDashboard]
  );

  const handleDownloadExcelClick = useCallback(
    () => generateTrucksExcel(trucks),
    [trucks]
  );

  // Do not display loader when search is active
  const showLoadingHeader = useMemo(
    () => refetchingTrucks || pollingTrucks,
    [pollingTrucks, refetchingTrucks]
  );

  const dashboardActions = useMemo<DialAction[]>(
    () => [
      ...(isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT)
        ? [
            {
              name: "Empresa Contratista",
              onClick: () => setOpenCerroAltoDialog(true),
              icon: "EC",
            },
            {
              name: "PM / Pre PM",
              onClick: () => setOpenPlannedMaintenancesDialog(true),
              icon: "PM",
            },
          ]
        : []),
      ...(isAllowedTo(Can.READ, Module.OPERATOR_ASSIGNMENT)
        ? [
            {
              name: "Registro CAEX Armados",
              onClick: () => setOpenOperativeTrucksDialog(true),
              icon: <ContentPasteRounded />,
            },
          ]
        : []),
      ...(isDataOutdated
        ? [
            {
              name: "Descargar",
              onClick: handleDownloadExcelClick,
              icon: <ExcelIcon />,
            },
          ]
        : []),
    ],
    [handleDownloadExcelClick, isAllowedTo, isDataOutdated]
  );

  return (
    <>
      <Header
        elapsedMinutes={magnetElapsedMinutes}
        onSearchQueryChange={setSearchValue}
        searchPlaceholder={"Buscar por CAEX"}
        clearOnBlur
        loading={showLoadingHeader}
      />
      <ScreenContainer>
        <OperatorAssignmentKpis />
        <Box className={classes.trucksGroupsRoot}>
          {loadingTrucks ? (
            <>
              {Array.from(Array(4).keys()).map((i) => (
                <TruckGroupSkeleton key={i} />
              ))}
            </>
          ) : (
            dashboardTrucksData.map(({ columns, group }) => (
              <TruckGroup key={group}>
                {columns.map(({ trucks, column }) => (
                  <TruckColumn
                    key={column}
                    title={columnTypeTranslation(column, withSpace)}
                    chipText={`${trucks.length}`}
                  >
                    {trucks.map((truck) => (
                      <TruckCard
                        key={truck.id}
                        truck={truck}
                        type={column}
                        groupType={group}
                        onClick={onClickNavigate}
                      />
                    ))}
                  </TruckColumn>
                ))}
              </TruckGroup>
            ))
          )}
        </Box>
        {isDataOutdated && (
          <CustomSnackbar
            open={true}
            onClose={() => {}}
            data={{
              severity: "error",
              text: "Problemas de actualización de datos",
            }}
          />
        )}
        <OperatorAssignmentSpeedDial
          ariaLabel="trucks-dashboard-speed-dial"
          actions={dashboardActions}
          speedDialBaseIcon={<TruckIcon />}
          openIcon={<CloseSharp />}
          onClose={onCloseSpeedDial}
          onOpen={onOpenSpeedDial}
          open={openSpeedDial}
        />
      </ScreenContainer>
      <AddTrucksCerroAltoDialog
        open={openCerroAltoDialog}
        onClose={handleOnDialogClose}
        onComplete={handleOnDialogComplete}
      />
      <ShiftOperativeTrucksDialog
        open={openOperativeTrucksDialog}
        onClose={handleOnOperativeTrucksDialogClose}
      />
      <PlannedMaintenancesDialog
        open={openPlannedMaintenancesDialog}
        onClose={handleOnDialogClose}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type, background } = palette;
  const cellBackground =
    type === "light" ? palette.secondary.main : palette.common.white;

  return {
    trucksGroupsRoot: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: type === "light" ? background.paper : background.default,
      flexGrow: 1,
      paddingBottom: 34,
      marginTop: 34,
    },
    speedDial: {
      position: "absolute",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    speedDialIcon: {
      color: palette.common.white,
    },
    actions: {
      backgroundColor: palette.primary.dark,
      color: palette.common.white,
      "&:hover": {
        backgroundColor: palette.primary.dark,
      },
    },
    actionsTooltips: {
      fontSize: theme.typography.body1.fontSize,
      backgroundColor: cellBackground,
      color: palette.getContrastText(cellBackground),
    },
  };
});
