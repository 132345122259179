import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import {
  Typography,
  makeStyles,
  Button,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { OperatorList } from ".";
import {
  Operator,
  Location,
  RefetchOperatorsFunctionType,
  RefetchOperatorsReason,
  RefetchKpiFunctionType,
  Module,
  Can,
  ErrorMsg,
  OperatorAlertType,
} from "interfaces";
import { Add, Edit } from "@material-ui/icons";
import clsx from "clsx";
import { useData } from "hooks";
import {
  AddOperatorDialog,
  ChipGroup,
  ChipGroupSkeleton,
  ChipSelectorOptions,
  Restricted,
} from "components";
import { groupBy, map } from "lodash";
import { UserContext } from "contexts";
import { useParams } from "react-router";

interface OperatorTableProps {
  operators: Operator[];
  truckModel?: string;
  refetchOperators: RefetchOperatorsFunctionType;
  refetchKpis?: RefetchKpiFunctionType;
  onChange?: (editionMode: boolean) => void;
  title?: string;
  alwaysEditable?: boolean;
  clearFilter?: boolean;
  loadingData: boolean;
}

interface Header {
  name: string;
  className: string;
}

export const OperatorsTable: React.FC<OperatorTableProps> = ({
  operators,
  truckModel,
  onChange = () => null,
  title,
  refetchOperators,
  refetchKpis,
  alwaysEditable = false,
  clearFilter,
  loadingData,
}) => {
  const { isAllowedTo } = useContext(UserContext);
  const [editionMode, setEditionMode] = useState<boolean>(() => alwaysEditable);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<number>();
  const [selectedCompetency, setSelectedCompetency] = useState<number>();
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const canManageOperators = isAllowedTo(Can.WRITE, Module.OPERATOR_ASSIGNMENT);
  const classes = useStyles();

  const { equipId } = useParams<"equipId">();


  const isEditionActive = editionMode && canManageOperators;
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const { data: locations, firstLoading: firstLoadingLocation } = useData<
    Location[]
  >(
    {
      config: {
        url: "/operator-assignment/locations",
        params: {
          entity: "operator",
          limit: 5.2,
        },
      },
    },
    ErrorMsg.GET_LOCATIONS
  );

  const locationIds = useMemo(
    () => new Set<number>(map(locations ?? [], "id")),
    [locations]
  );
  const locationOptions = useMemo<ChipSelectorOptions[]>(() => {
    if (!locations) return [];
    const groupedOperators = groupBy(operators, "location.id");

    const allOption: ChipSelectorOptions = {
      id: 0,
      name: `TODOS`,
      count: operators.length,
    };

    const parsedLocations: ChipSelectorOptions[] = [];
    // let locationsCounter = 0;
    for (let loc of locations) {
      const { id, alias } = loc;
      const q = groupedOperators[id]?.length ?? 0;
      parsedLocations.push({
        id,
        name: `${alias}`,
        count: q,
      });
    }

    return [allOption, ...parsedLocations /*otherOption*/];
  }, [operators, locations]);

  const competencyOptions = useMemo<ChipSelectorOptions[]> (() => {
    const groupedOperators = groupBy(operators, "qualifs.maxAlert.type");
    const countAlertsWarning = groupedOperators[OperatorAlertType.WARNING]?.length ?? 0 
    const countAlertsUnable = groupedOperators[OperatorAlertType.UNABLE]?.length ?? 0  

    const allOption: ChipSelectorOptions = {
      id: 1000,
      name: `ALERTA`,
      count: countAlertsWarning + countAlertsUnable,
    };
    return [allOption]
  },[operators]);

  const filteredOperators = useMemo(
    () =>{
      if(selectedCompetency){
        return operators.filter(
          (op) =>
             op.qualifs &&
             [OperatorAlertType.WARNING, OperatorAlertType.UNABLE].includes(op.qualifs.maxAlert?.type))
      }else{
        return operators.filter(
          (op) =>
            selectedLocation === 0 ||
            op.location?.id === selectedLocation ||
            (selectedLocation === -1 &&
              (!op.location || !locationIds.has(op.location.id))))
      }

  }, [operators, locationIds, selectedLocation, selectedCompetency]);

  const headers: Header[] = useMemo(
    () => [
      {
        name: isSmall ? "Operador" : "Nombre operador",
        className: classes.headerSizeXL,
      },
      { name: "Competencias", className: classes.headerSizeS },
      { name: "Ubicación", className: classes.headerSizeM },
      {
        name: isSmall ? "Equipo" : "Equipo | Código",
        className: classes.headerSizeM,
      },
      { name: "Titular", className: classes.headerSizeM },
      { name: "Area / Estado", className: classes.headerSizeL },
      { name: "Disponibilidad", className: classes.headerSizeM },
      {
        name: isEditionActive ? "Deshabilitar" : "Deshabilitado",
        className: classes.headerSizeS,
      },
      // { name: "Bus", className: classes.headerSizeM },
      ...(editionMode && canManageOperators
        ? [{ name: "Eliminar", className: classes.headerSizeS }]
        : []),
    ],
    [
      isSmall,
      classes.headerSizeXL,
      classes.headerSizeM,
      classes.headerSizeL,
      classes.headerSizeS,
      isEditionActive,
      editionMode,
      canManageOperators,
    ]
  );

  const handleEditMode = useCallback(
    (newEditionMode: boolean) => {
      setEditionMode(newEditionMode);
      onChange(newEditionMode);
    },
    [onChange]
  );

  const onDialogClose = useCallback(
    (e?: unknown, reason?: "backdropClick" | "escapeKeyDown") =>
      reason !== "backdropClick" && setOpenDialog(false),
    [setOpenDialog]
  );

  const onDialogComplete = useCallback(
    () => refetchOperators(RefetchOperatorsReason.ADDED),
    [refetchOperators]
  );

  useEffect(() => {
    if (clearFilter) {
      setSelectedLocation(0);
      setSelectedCompetency(undefined);
    }
  }, [clearFilter, setSelectedLocation]);

  useEffect(() => {
    if (isFirstRender) {
      setSelectedLocation(0);
      setSelectedCompetency(undefined);
      setIsFirstRender(false);
      return;
    }
  }, [isFirstRender]);

  const FilterTableSection = () => {
    const showAllFilters = !equipId
    return (
      <>
      { showAllFilters && <Typography className={ classes.chipGroupTitle }><strong>Ubicación</strong></Typography> }
      <ChipGroup
        value={selectedLocation}
        options={locationOptions}
        onChange={(value) => {
          setSelectedLocation(+value);
          setSelectedCompetency(undefined);
        }}
      />
       { showAllFilters && (
        <>
          <span className={classes.divider}></span>
          <Typography className={ classes.chipGroupTitle }><strong>Competencias</strong></Typography>
          <ChipGroup
            value={selectedCompetency}
            options={competencyOptions}
            onChange={(value) => {
              setSelectedCompetency(+value);
              setSelectedLocation(undefined)
            }} 
            /> 
        </>
        )}
    </>
  )}
  return (
    <>
      <div className={classes.operatorTable}>
        <div className={classes.contentHeader}>
          <div className={classes.contentHeaderSections}>
            {title && (
              <Typography className={classes.sectionTitle} variant="h6">
                <strong>{title}</strong>
              </Typography>
            )}
          </div>
          {!firstLoadingLocation ? (
            <FilterTableSection />
          ) : (
            <ChipGroupSkeleton chipNumber={5 + 2} />
          )}
          <div
            className={clsx(
              classes.contentHeaderSections,
              classes.headerButtons
            )}
          >
            <Restricted to={canManageOperators}>
              <Button
                className={classes.button}
                color="primary"
                onClick={() => setOpenDialog(true)}
                startIcon={<Add />}
              >
                AGREGAR OPERADOR
              </Button>
            </Restricted>
            <Restricted to={!alwaysEditable && canManageOperators}>
              <Button
                className={clsx(classes.button, classes.editButton)}
                variant="outlined"
                color="primary"
                onClick={() => handleEditMode(!editionMode)}
                startIcon={!editionMode ? <Edit /> : null}
              >
                {editionMode ? "VOLVER A PREARMADO" : "EDITAR"}
              </Button>
            </Restricted>
          </div>
        </div>
        <div className={classes.operatorsHeader}>
          {headers.map(({ className, name }, i) => (
            <div
              className={clsx(className, classes.header)}
              key={`header-${i}`}
            >
              <Typography>
                <b>{name}</b>
              </Typography>
            </div>
          ))}
        </div>
        <OperatorList
          editMode={isEditionActive}
          truckModel={truckModel}
          operators={filteredOperators}
          refetchOperators={refetchOperators}
          refetchKpis={refetchKpis}
          loadingData={loadingData}
        />
      </div>
      <AddOperatorDialog
        open={openDialog}
        onClose={onDialogClose}
        onComplete={onDialogComplete}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  return {
    operatorTable: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flex: "1",
      paddingBottom: 24,
    },
    operatorsHeader: {
      backgroundColor:
        palette.type === "light"
          ? palette.secondary.main
          : palette.common.white,
      height: 48,
      display: "flex",
      alignItems: "center",
    },
    header: {
      color:
        palette.type === "light" ? palette.common.white : palette.common.black,
      fontWeight: 500,
      padding: "10px 20px",
    },
    headerSizeXL: {
      maxWidth: 300,
      width: "100%",
    },
    headerSizeL: {
      maxWidth: 220,
      width: "100%",
    },
    headerSizeM: {
      maxWidth: 200,
      width: "100%",
    },
    headerSizeS: {
      maxWidth: 125,
      width: "100%",
      textAlign: "center",
    },
    contentHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginTop: 23,
      marginBottom: 23,
    },
    contentHeaderSections: {
      display: "flex",
      marginRight: 25,
    },
    sectionTitle: {
      lineHeight: "24px",
      paddingRight: "20px",
    },
    headerButtons: {
      marginLeft: "auto",
      justifyContent: "flex-end",
    },
    button: {
      minHeight: 42,
    },
    editButton: {
      color: palette.primary.main,
      borderColor: palette.primary.main,
      marginLeft: "30px",
    },
    chipGroupTitle: {
      paddingRight: 16,
      fontStyle: "bold"
    },
    divider: {
      width: "1px",
      height: "50%",
      backgroundColor: theme.palette.text.primary,
      margin: "0px 44px"
    }
  };
});
