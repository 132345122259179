import { Operator } from "./operator.interface";
import { Location } from "./location.interface";
import { Truck } from "./truck.interface";

export enum ColumnGroupType {
  MAINTENANCE = "MAINTENANCE",
  DETENTIONS = "DETENTION",
}

export enum ColumnType {
  PENDING = "PENDING",
  PRE_ASSEMBLED = "PRE_ASSEMBLED",
  NO_OPERATOR = "NO_OPERATOR",
  UNPLANNED_WAIT = "UNPLANNED_WAIT",
}

export const ColumnGroupMapping = {
  [ColumnType.PENDING]: ColumnGroupType.MAINTENANCE,
  [ColumnType.PRE_ASSEMBLED]: ColumnGroupType.MAINTENANCE,
  [ColumnType.NO_OPERATOR]: ColumnGroupType.DETENTIONS,
  [ColumnType.UNPLANNED_WAIT]: ColumnGroupType.DETENTIONS,
};

export enum CardAlertType {
  ALERT = "ALERT",
  WARNING = "WARNING",
  DEFAULT = "DEFAULT",
}

export enum PlannedMaintenanceType {
  PM = "PM",
  PRE_PM = "PRE_PM",
}

export const plannedMaintenanceTypeTranslation: Record<
  PlannedMaintenanceType,
  string
> = {
  [PlannedMaintenanceType.PM]: "PM",
  [PlannedMaintenanceType.PRE_PM]: "PRE PM",
};

export enum TruckProjectionType {
  ASSETS_HEALTH = "ASSETS_HEALTH",
  MANUAL = "MANUAL",
}

export const columnGroupTypeTranslation: Record<ColumnGroupType, string> = {
  [ColumnGroupType.MAINTENANCE]: "CAEX en mantención por salir",
  [ColumnGroupType.DETENTIONS]: "CAEX en detención no programada",
};

export const columnTypeTranslation = (
  columType: ColumnType,
  withReasonId: boolean
): string => {
  return columType === ColumnType.PENDING
    ? "MANTENCIÓN"
    : columType === ColumnType.PRE_ASSEMBLED
    ? "PRE-ARMADOS EN MANT."
    : columType === ColumnType.NO_OPERATOR
    ? withReasonId
      ? "SIN OPERADOR | 4000"
      : "SIN OPERADOR"
    : columType === ColumnType.UNPLANNED_WAIT
    ? withReasonId
      ? "ESPERA NO PLANIFICADA | 4190"
      : "ESPERA NO PLANIFICADA"
    : "";
};

export interface TruckSummary extends Truck {
  issueDate: string;
  issuedWithinCurrentShift: boolean;
  location?: Location;
  currentOper: Pick<Operator, "id" | "name">;
  lastOper: Pick<Operator, "id" | "name">;
  preassembledOper: Pick<Operator, "id" | "name" | "location">;
  maintenance?: {
    level1: string;
    level2: string;
    comment: string;
  };
  releaseProjectionTime: string | null;
  releaseProjectionType: TruckProjectionType;
  isReleaseProjectionWithinCurrentShift: boolean;
  userComment?: string;
  hasPrePm: boolean;
  group: ColumnGroupType;
  column: ColumnType;
  alertType: CardAlertType | null;
}

// API response
export type DashboardTrucksData = {
  group: ColumnGroupType;
  columns: ColumnData[];
}[];

export interface ColumnData {
  column: ColumnType;
  trucks: TruckSummary[];
}

export interface TruckEditableInfo {
  location?: Location;
  proyection: string | Date | null;
  assignmentComment: string | null;
}
export interface TruckEditionRequestBody
  extends Omit<TruckEditableInfo, "location"> {
  locationId?: number;
}

export interface UnplannedParkedTruck {
  id: string;
  reasonId: number;
  distance: number;
  lost: boolean;
  parkedAt: string;
  selectedLocation: Location;
  finalLocation: Location | null;
  comment: string;
  operativeAt: string;
}

export interface LostTruckScreenInfo {
  isEditable: boolean;
  editionHelperText: string;
  mailingHelperText: string;
}

export interface ShiftOperativeTruck {
  id: string;
  equipId: string;
  operator: Pick<Operator, "id" | "name">;
  startDate: string;
  duration: number;
  operativeAt: string;
  fromReason: number;
  assignedWithTub: boolean;
  assignedMultipleTimes: boolean;
  includedForAdherence: boolean;
}

export interface PlannedMaintenanceTruck {
  id: string;
  type: PlannedMaintenanceType;
  startDate: Date | string | null;
}

export type UnplannedParkedEditableInfo = Pick<
  UnplannedParkedTruck,
  "id" | "lost" | "comment"
> & {
  locationId?: string;
};

export type UnplannedParkedParams = Pick<
  UnplannedParkedTruck,
  "id" | "lost" | "comment"
> & {
  locationId?: number;
  locationTacticalAssetId?: number;
};
