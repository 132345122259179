import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import BhpLogo  from '../../assets/images/BhpLogo.png';
import BusIcon  from '../../assets/images/BusIcon.png';
import ShiftIcon  from '../../assets/images/ShiftIcon.png';
import CalendarIcon  from '../../assets/images/CalendarIcon.png';
import TimeIcon  from '../../assets/images/TimeIcon.png';
import GreenIcon  from '../../assets/images/GreenIcon.png';
import ShovelIcon  from '../../assets/images/ShovelIcon.png';
import { useData } from 'hooks';
import { StatusInterface } from 'contexts/ShifChangeContext';
import { ErrorMsg } from 'interfaces';
import { IData } from './ShiftChangeScreen';

// Font.register({
//   family: 'Roboto',
//   fonts: [
//     { src: '/fonts/Roboto-Regular.ttf'},
//     { src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold'}
//   ]
//   });

// Definir los estilos
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontSize: 10,
    backgroundColor: '#1E242B',
    color: '#FFF',
  },
  image: {
    width: 15,
    height: 12,
  },
  logo: {
    width: 30,
    height: 12
  },
  busIcon: {
    width: 30,
    height: 35,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
  },
  headerComponents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  columnContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 8
  },
  tableWrapper: {
    width: '100%',
    padding: 5,
    backgroundColor: '#2D3339',
    margin: 12,
  },
  tableHeader: {
    width: '70%',
    height: 30,
    backgroundColor: '#2D3339',
    fontSize: 9,
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginBottom: 11,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  lineHeader: {
    height: 12,
    width: 0,
    backgroundColor: '#1E242B',
    border: '1px solid #1E242B'
  },
  lineTitle: {
    height: 14,
    width: 0,
    border: '1px solid #2D3339'
  },
  titleContainer: {
    height: 20,
    width: '100%',
    marginLeft: 8,
    marginTop: 8,
    marginRight: 8,
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold'
  },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '30%',
    fontSize: 12,
    color: '#fff',
    fontWeight: 'bold'
  },
  shiftInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '65%',
    fontSize: 6,
    color: '#fff',
    height:20,
    marginLeft: 8
  },
  shiftIcon:{
    height:10,
    width: 10,
  },
  shiftBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '25%',
    backgroundColor: '#2D3339',
    color: '#FFFFFF',
    height: 20,
    padding: 4,
    marginRight: 4,
  },
  dateBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '40%',
    backgroundColor: '#2D3339',
    color: '#FFFFFF',
    height: 20,
    padding: 4,
    marginRight: 4,
  },
  timeBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '35%',
    backgroundColor: '#2D3339',
    color: '#FFFFFF',
    height: 20,
    padding: 4
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    flex: 1,
    borderBottomColor: '#fff',
    borderTop: '#fff',
    borderLeft: '#fff',
    borderRight: '#fff',
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: 2,
    textAlign: 'center',
  },
  tableColHeaderNumber: {
    width: '5%',
    borderBottomColor: '#fff',
    borderTop: '#fff',
    borderLeft: '#fff',
    borderRight: '#fff',
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: 2,
    textAlign: 'center',
  },
  tableColHeaderName: {
    width: '45%',
    borderBottomColor: '#fff',
    borderTop: '#fff',
    borderLeft: '#fff',
    borderRight: '#fff',
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    color: '#000000',
    padding: 2,
    textAlign: 'center',
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderColor: '#1E242B',
    borderBottomColor: '#1E242B',
    borderWidth: 1,
    padding: 2,
    textAlign: 'center',
  },
  tableColName: {
    width: '45%',
    borderStyle: 'solid',
    borderColor: '#1E242B',
    borderBottomColor: '#1E242B',
    borderWidth: 1,
    padding: 2,
    textAlign: 'center',
  },
  tableColNumber: {
    width: '5%',
    borderStyle: 'solid',
    borderColor: '#1E242B',
    borderBottomColor: '#1E242B',
    borderWidth: 1,
    padding: 2,
    textAlign: 'center',
  },
  colPar: {
    backgroundColor: '#1E242B',
  },
  colImpar: {
    backgroundColor: '#2D3339',
  },
  tableCellHeader: {
    fontSize: 8,
    fontWeight: 'bold',
    //fontFamily: 'Roboto',
    color: '#000000',
  },
  tableCell: {
    fontSize: 7,
    //fontFamily: 'Roboto',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    width: 8,
    height: 8,
    marginRight: 5,
  },
});

interface IPDFInfo {
  shift: string;
  dateFormat: string;
  dataOperators: IData;
}

const ShiftChangePDF = (props: IPDFInfo) => {
  const { shift, dateFormat, dataOperators } = props;
  const iconToShow = (equipType: string, equipment:string, ubication: string) => {
    if (equipType === 'Palas') {
      return <Image style={styles.icon} src={ShovelIcon} />
    }
    else {
      if (equipment === 'NINGUNA' || ubication === 'NINGUNA'){
        return <Image style={styles.icon} src={GreenIcon} />
      }
      return <View style={styles.icon}/>
    }
  }
  const time = new Date()
  const formatTime = (date: Date) => {
    return new Intl.DateTimeFormat("es-ES", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    }).format(date);
  };

  return (
    <Document>
    <Page size="A5" style={styles.page} wrap>
      <View style={styles.titleContainer}>
        <View style={styles.titleBox}>
          <Image style={styles.logo} src={BhpLogo} />
          <View style={styles.lineTitle} />
          <Text style={styles.title}>Con Asignación</Text>
        </View>
        <View style={styles.shiftInfo}>
          <View style={styles.shiftBox}>
            <Image style={styles.shiftIcon} src={ShiftIcon} />
            <Text>Turno</Text>
            <View style={styles.lineHeader}/>
            <Text>{shift.toString()}</Text>
          </View>
          <View style={styles.dateBox}>
            <Image style={styles.shiftIcon} src={CalendarIcon} />
            <Text>Fecha de hoy</Text>
            <View style={styles.lineHeader}/>
            <Text>{dateFormat}</Text>
          </View>
          <View style={styles.timeBox}>
            <Image style={styles.shiftIcon} src={TimeIcon} />
            <Text>Hora Actual</Text>
            <View style={styles.lineHeader}/>
            <Text>
              {formatTime(time)}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.headerComponents}>
      </View>
      <View style={styles.columnContainer}>
        {dataOperators.map((dataOp, index) => (
          <View style={styles.tableWrapper} key={index}>
            <View style={styles.tableHeader}>
              <Image style={styles.image} src={BusIcon} />
              <Text>BUS DE TRASLADO</Text>
              <View style={styles.lineHeader} />
              <Text>{dataOp.bus}</Text>
            </View>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeaderNumber}>
                  <Text style={styles.tableCellHeader}>#</Text>
                </View>
                <View style={styles.tableColHeaderName}>
                  <Text style={styles.tableCellHeader}>Nombre Operador</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Equipo</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCellHeader}>Ubicación</Text>
                </View>
              </View>
              {dataOp.busData.map((item, itemIndex) => (
                <View style={styles.tableRow} key={itemIndex}>
                  <View style={[styles.tableColNumber, itemIndex % 2 === 0 ? styles.colImpar : styles.colPar]}>
                    <Text style={styles.tableCell}>{itemIndex + 1}</Text>
                  </View>
                  <View style={[styles.tableColName, itemIndex % 2 === 0 ? styles.colImpar : styles.colPar]}>
                    <View style={styles.row}>
                      {/* Aquí podrías agregar una condición para mostrar el ícono */}
                      {/* <Image style={styles.icon} src="path-to-your-icon.png" /> */}
                      {iconToShow(item.TYPE, item.EQUIPMENT, item.DESTINATION)}
                      <Text style={styles.tableCell}>{item.NAME}</Text>
                    </View>
                  </View>
                  <View style={[styles.tableCol, itemIndex % 2 === 0 ? styles.colImpar : styles.colPar]}>
                    <Text style={styles.tableCell}>{item.EQUIPMENT}</Text>
                  </View>
                  <View style={[styles.tableCol, itemIndex % 2 === 0 ? styles.colImpar : styles.colPar]}>
                    <Text style={styles.tableCell}>{item.DESTINATION}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
  )
}


export default ShiftChangePDF;

