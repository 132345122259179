import { LinearProgress, RadioGroup, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  Bus,
  Operator,
  Location,
  OperatorEditableInfo,
  RefetchOperatorsFunctionType,
  RefetchOperatorsReason,
  RefetchKpiFunctionType,
  ErrorMsg,
} from "interfaces";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { OperatorListEditableItem, OperatorListItem } from ".";
import { ConfirmationDialogContext, OperatorAssignmentContext } from "contexts";
import { useData, useDebouncedCallback } from "hooks";
import { logError } from "services";
import { CloseOnClick, AutoSizer, List } from "components";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";

interface OperatorListProps {
  operators: Operator[];
  truckModel?: string;
  editMode?: boolean;
  refetchOperators: RefetchOperatorsFunctionType;
  refetchKpis?: RefetchKpiFunctionType;
  loadingData: boolean;
}

interface ListChildProps {
  key: React.Key;
  index: number;
  style: React.CSSProperties;
}

type PendingChanges = Record<
  OperatorEditableInfo["operId"],
  OperatorEditableInfo
>;

export const OperatorList: React.FC<OperatorListProps> = ({
  operators,
  truckModel,
  editMode,
  refetchOperators,
  refetchKpis,
  loadingData,
}) => {
  const classes = useStyles();

  //Hooks
  const { selectedOperator, setSelectedOperator } = useContext(
    OperatorAssignmentContext
  );
  const { enqueueSnackbar } = useSnackbar();

  const { openConfirmationDialog } = useContext(ConfirmationDialogContext);
  const [scrollToIndex, setScrollToIndex] = useState(0);

  const [, setPendingOperatorsUpdates] = useState<PendingChanges>({});

  const { data: locations } = useData<Location[]>(
    {
      config: {
        url: "/operator-assignment/locations",
        method: "GET",
        params: {
          entity: "operator",
        },
      },
    },
    ErrorMsg.GET_LOCATIONS
  );

  const { data: busesData } = useData<Bus[]>(
    {
      config: "/operator-assignment/buses",
    },
    ErrorMsg.GET_BUSES
  );

  const { refetching: deleteOperatorLoading, refetch: deleteOperator } =
    useData<unknown, [Operator["id"]]>(
      {
        config: {
          url: "/operator-assignment/shift-crew/operators",
          method: "DELETE",
        },
        options: {
          manual: true,
        },
      },
      ErrorMsg.DELETE_OPERATOR
    );

  const { refetching: updateOperatorLoading, refetch: updateOperator } =
    useData<unknown, OperatorEditableInfo[]>(
      {
        config: {
          url: "/operator-assignment/operators",
          method: "PATCH",
        },
        options: {
          manual: true,
        },
      },
      ErrorMsg.UPDATE_OPERATORS
    );

  const loadingAction = useMemo(
    () => deleteOperatorLoading || updateOperatorLoading,
    [deleteOperatorLoading, updateOperatorLoading]
  );

  useLayoutEffect(() => {
    const seletectOperatorIndex = operators.findIndex((op) => {
      const selectedOperId = selectedOperator?.id ?? 0;
      return op.id === selectedOperId;
    });
    setScrollToIndex(seletectOperatorIndex);
  }, [operators, selectedOperator?.id]);

  const handleSelectedOperator = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const operator = operators.find((oper) => oper.id === event.target.value);
      setSelectedOperator(operator ?? null);
    },
    [setSelectedOperator, operators]
  );

  const buses = useMemo(
    () => busesData?.map((b) => ({ id: b.id, name: b.radioId })) ?? [],
    [busesData]
  );

  const saveChanges = useDebouncedCallback(async (changes: PendingChanges) => {
    try {
      await updateOperator({
        data: Object.values(changes),
      });
      enqueueSnackbar(`Cambios guardados`, { variant: "success" });
      setPendingOperatorsUpdates({});
      await refetchOperators(RefetchOperatorsReason.UPDATED);
      refetchKpis?.();
    } catch (e) {
      if (
        (e as AxiosError)?.code &&
        (e as AxiosError)?.code !== "ERR_CANCELED"
      ) {
        logError("UPDATE-OPERATOR-DETAILS", `Error: ${e}`);
      }
    }
  }, 800);

  const onChange = useCallback(
    (operInfo: OperatorEditableInfo) => {
      setPendingOperatorsUpdates((prevState) => {
        const newState = {
          ...prevState,
          [operInfo.operId]: {
            ...prevState[operInfo.operId],
            ...operInfo,
          },
        };
        saveChanges(newState);
        return newState;
      });
    },
    [saveChanges]
  );

  const deleteOperators = useCallback(
    async (id: string) => {
      try {
        await deleteOperator({
          data: [id],
        });
        await refetchOperators(RefetchOperatorsReason.DELETED);
        refetchKpis?.();
      } catch (e) {
        logError("DELETE-OPERATOR", `Error: ${e}`);
      }
    },
    [deleteOperator, refetchKpis, refetchOperators]
  );

  const onDelete = useCallback(
    async (id: string, name: string) => {
      openConfirmationDialog({
        title: "Confirmación",
        content: `¿Está seguro que desea eliminar al operador ${name} de la banca?`,
        onCancelInfo: {
          text: "Cancelar",
          onClick: () => null,
          closeOnClick: CloseOnClick.BEFORE,
        },
        onCompleteInfo: {
          text: "Eliminar",
          onClick: () => deleteOperators(id),
          closeOnClick: CloseOnClick.BEFORE,
        },
      });
    },
    [openConfirmationDialog, deleteOperators]
  );

  const Row = useCallback(
    ({ index, style }: ListChildProps) => (
      <OperatorListItem
        key={operators[index].id}
        operator={operators[index]}
        truckModel={truckModel}
        style={style}
        loadingData= {loadingData}
      />
    ),
    [operators, truckModel, loadingData]
  );

  const EditableRow = useCallback(
    ({ index, style }: ListChildProps) => (
      <OperatorListEditableItem
        key={operators[index].id}
        operator={operators[index]}
        style={style}
        locations={locations ?? []}
        buses={buses}
        onChange={onChange}
        onDelete={onDelete}
      />
    ),
    [operators, locations, buses, onChange, onDelete]
  );

  return (   
    <Paper elevation={0} className={classes.operatorListPaper}>
      <RadioGroup
        value={selectedOperator?.id ?? null}
        onChange={handleSelectedOperator}
      >
        {(loadingAction || loadingData) && (
          <LinearProgress className={classes.linearLoader} />
        )}
        <AutoSizer>
          {({ height, width }) => {
            return (
              <List
                width={width}
                height={height}
                rowHeight={68}
                rowRenderer={editMode ? EditableRow : Row}
                rowCount={operators.length}
                overscanRowCount={16}
                scrollToIndex={scrollToIndex}
                noRowsRenderer={() =>
                  !loadingData ? (
                    <Typography
                      className={classes.notFoundText}
                      variant={"h6"}
                      noWrap
                    >
                      No se encontraron operadores
                    </Typography>
                  ) : (
                    <></>
                  )
                }
              />
            );
          }}
        </AutoSizer>
      </RadioGroup>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => {
  const { palette } = theme;
  const { type } = palette;
  return {
    notFoundText: {
      textAlign: "center",
      marginTop: 20,
    },
    searchBarRoot: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      height: 64,
      boxShadow: "0px 4px 4px 0px rgb(0 0 0 / 25%)",
      backgroundColor:
        type === "light"
          ? palette.background.paper
          : palette.background.default,
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    dialogInfTruckPaper: {},
    header: {
      position: "sticky",
      zIndex: 1,
    },
    operatorListPaper: {
      backgroundColor: palette.background.default,
      flex: "1 1 auto",
      height: "100%",
      width: "100%",
      display: "grid",
    },
    listDetailRoot: {
      maxHeight: 470,
      width: "100%",
    },
    listItemOperDetail: {
      padding: "4px 16px",
      "&:last-child": {
        paddingBottom: 8,
      },
    },
    linearLoader: {
      height: 5,
      top: 0,
      position: "absolute",
      width: "100%",
      zIndex: 1,
    },
  };
});
