export enum AppRoute {
  HOME = "/",
  LOGIN = "/login",
  SOURCE_INFO_STATUS = "/data-status",
  TRUCKS_DASHBOARD = "/operator-assignment/maintenance",
  UNPLANNED_LOCATIONS_SCREEN = "/operator-assignment/unplanned-parked",
  OPERATORS_SCREEN = "/operator-assignment/operators",
  TRUCK_DETAILS = "/operator-assignment/:equipId",
  TA_BALANCE_SCREEN = "/truck-assignment/balance",
  TA_METRICS_SCREEN = "/truck-assignment/metrics",
  TA_PERFORMANCE_SCREEN = "/truck-assignment/performance",
  TA_DAILY_PLAN_SCREEN = "/truck-assignment/daily-plan",
  PERFORMANCE_RATINGS = "/performance-ratings",
  PERFORMANCE_RATINGS_SUMMARY = "/performance-ratings/summary",
  ADMIN = "/admin",
  SIC = "/sic",
  RELIEVE = "/operator-assignment/relay",
  TA_VELOCITY_SCREEN = "/truck-assignment/velocity",
  SC_SHIFT_CHANGE_OPERATOR_SCREEN = '/shift-change/operators',
  SC_SHIFT_CHANGE_NOT_OPERATOR_SCREEN = '/shift-change/info'
}
